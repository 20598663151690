import { Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import CustomModal from "components/CustomModal";
import ListReasonApproveOrder from "features/Order/Detail/components/ListReasonApproveOrder";
import { useApproveOrder } from "hooks/order";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { notify } from "utils/helperFuncs";

const CustomModalApprovalOrder = ({ setOpenModalApprovalOrder, selectedList, resetCheckedList, orderIDs }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [warehouseID, setWarehouseID] = useState(selectedList[0]?.exportedWarehouseID);
  const { handleApproveOrder, loading: loadingApprove } = useApproveOrder();

  const handleConfirm = async () => {
    try {
      await form.validateFields();
      await handleApproveOrder({
        orderIDs,
        updateWarehouseID: warehouseID === selectedList[0]?.exportedWarehouseID ? undefined : warehouseID,
      })
        .then(() => {
          notify.success({ message: t("order.approvalOrder.success") });
          resetCheckedList && resetCheckedList();
          setOpenModalApprovalOrder(false);
        })
        .catch((error) => {
          notify.error({
            message: t("order.approvalOrder.error"),
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => setOpenModalApprovalOrder(false);

  return (
    <CustomModal
      closable={false}
      centered
      footer={false}
      isBlockCloseOnOke
      selfVisibleControlled={false}
      onOke={handleConfirm}
      onCancel={handleCancel}
      buttonLoading={loadingApprove}
      title={<Typography.Title level={3}>{t("order.approvalOrder.title")}</Typography.Title>}
      width="50%"
    >
      <ListReasonApproveOrder
        loadingApprove={loadingApprove}
        chatURL={selectedList[0]?.chatURL}
        deliveryDeadline={selectedList[0]?.deliveryDeadline}
        preOrder={selectedList[0]?.preOrder}
        warehouseName={selectedList[0]?.exportedWarehouse}
        warehouseBranch={selectedList[0]?.exportedWarehouseBranch}
        warehouseNameID={selectedList[0]?.exportedWarehouseID}
        warehouses={selectedList[0]?.seller?.warehouses}
        orderID={orderIDs?.[0]}
        form={form}
        dataSource={selectedList[0]?.items}
        setWarehouseID={setWarehouseID}
        messages={selectedList[0]?.messages}
        seller={selectedList[0]?.seller}
        stockStatus={selectedList?.[0]?.stockStatus}
      />
    </CustomModal>
  );
};

export default CustomModalApprovalOrder;
