import { Col, Row, Select, Typography } from "antd";
import EllipsisText from "components/_storybooks/EllipsisText";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { useWarehouse } from "hooks/warehouse";
import { t } from "i18next";
import { Link } from "react-router-dom";

function WarehouseSelect({ options, defaultValue, dataSource, onGetValue, warehouseSelected, setHasProductLack }) {
  const { warehouses } = useWarehouse();

  const renderChildrenInStock = (warehouses) => {
    return warehouses?.map((warehouse, index) => ({
      title: (
        <span
          style={
            warehouse?.id === warehouseSelected?.value
              ? { textDecoration: "underline #1953D8 2px", color: "#1953D8" }
              : {}
          }
        >
          <EllipsisText
            style={{ color: warehouse?.id === warehouseSelected?.value ? "#1953D8" : "#5b6673" }}
            title={warehouse.name}
            line={2}
          />
        </span>
      ),
      key: index,
      width: warehouse?.name?.length > 30 ? 300 : 120,
      align: "center",
      editable: "false",
      render: (_, record) =>
        record?.product?.stocks?.filter((stock) => stock?.warehouse?.id === warehouse?.id)[0]?.quantity || "--",
    }));
  };

  const columns = [
    {
      title: t("order.orderDetail.parameter"),
      width: 250,
      fixed: "left",
      render: (text, record) => {
        return (
          <SpaceCustom>
            <Link target="_blank" to={`/product/detail/${record.productID}`}>
              <Typography.Link strong>{record?.productCode}</Typography.Link>
            </Link>
            <CustomTextHiddenLine text={record?.productName} strong line={2} />
            <Typography style={{ textAlign: "end", fontStyle: "italic" }}>{record?.product?.uom?.name}</Typography>
          </SpaceCustom>
        );
      },
    },
    {
      title: "Số lượng đại lý đặt",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        const whereWarehouse = record?.product?.stocks?.filter((item) => {
          return item?.warehouse?.id === warehouseSelected?.value;
        });
        const isLack = (whereWarehouse?.[0]?.quantity || 0) < record?.quantity;
        setHasProductLack && setHasProductLack(isLack);
        return (
          <SpaceCustom>
            <Typography.Text>{record?.quantity || "--"}</Typography.Text>
            {isLack && <Typography.Text type="warning">Không đủ tồn kho</Typography.Text>}
          </SpaceCustom>
        );
      },
    },
    {
      title: "Tồn kho khả dụng",
      children: renderChildrenInStock(warehouses),
      width: 300,
    },
  ];

  return (
    <SpaceCustom gap={8}>
      <Row gutter={[16]} style={{ alignItems: "center" }}>
        <Col>
          <Typography.Text strong>{t("cart.exportWarehouseLabel")}</Typography.Text>
        </Col>
        <Col>
          <Select
            defaultValue={defaultValue}
            options={options}
            onSelect={(value, option) => {
              onGetValue(option);
            }}
          />
        </Col>
      </Row>
      <CustomTable
        rowKey={(record) => record?.id}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{}}
      />
    </SpaceCustom>
  );
}

export default WarehouseSelect;
