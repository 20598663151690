import { SHIPPING_TYPE } from "config/constants";
import { formatDateTime } from "utils/dateTime";

export const convertGetAllOrder = (data) =>
  data?.map((item) => ({
    ...item,
    id: item?.id,
    createdAt: formatDateTime(item?.createdAt),
    number: item?.number,
    subTotal: item?.subTotal,
    total: item?.total,
    warehouses: item?.warehouses,
    exportedWarehouse: item?.exportedWarehouse?.name,
    exportedWarehouseID: item?.exportedWarehouse?.id,
    exportedWarehouseBranch: item?.exportedWarehouse?.branch,
    receiverName: item?.receiverContactName,
    orderPriority: item?.orderPriority,
    shippingType: item?.shippingType,
    paymentStatus: item?.paymentStatus,
    paymentMethod: item?.paymentMethod,
    status: item?.status,
    createdBy: item?.createdBy?.fullname,
    fullStockRequired: item?.fullStockRequired,
    remainingDeliverTime: item?.remainingDeliverTime,
    vat: item?.vat,
    receiverContactAddress: item?.receiverContactAddress,
    deliveryDeadline: item?.deliveryDeadline,
    chatURL: item?.chatURL,
    shippingUnit: convertShippingUnit(item?.shippingConfiguration, item?.shippingType, item?.shipperName),
    internalNote: item?.internalNote,
    inStockStatus: item?.stockStatus,
    completedAt: formatDateTime(item?.completedAt),
    fileURLs: item?.fileURLs,
    senderAddress: item?.senderAddress,
    shipperTelephone: item?.shipperTelephone,
    receiverContactPhone: item?.receiverContactPhone,
    note: item?.note,
    cancelReason: item?.cancelReason,
    preOrder: item?.preOrder,
    shippingConfiguration: item?.shippingConfiguration,
    paymentInfo: item?.paymentInfo,
    items: item?.items,
  }));

export const convertShippingUnit = (shippingConfiguration, shippingType, shipperName) => {
  switch (shippingType) {
    case SHIPPING_TYPE.BUS:
      return shippingConfiguration?.busConfig?.busName;
    case SHIPPING_TYPE.DELIVER_3PARTY:
      return shippingConfiguration?.deliver3PartyConfig?.provider?.name;
    case SHIPPING_TYPE.URBAN_COD:
      return shipperName ? `Shipper HA - ${shipperName}` : "Shipper HA";
    case SHIPPING_TYPE.PICK_UP_COUNTER:
      return;
    default:
      return;
  }
};

export const convertGetWarehouse = (data) =>
  data?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

export const convertGetCreatedBy = (data) =>
  data?.map((item) => ({
    value: item?.id,
    label: item?.fullname,
  }));

export const convertPagination = (data) => {
  return {
    total: data?.total,
    pageSize: data?.limit,
    current: data?.offset / data?.limit + 1,
    showSizeChanger: false,
  };
};
